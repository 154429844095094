import { css } from "@hellodarwin/core/lib/plugins/styled";
import coreTheme from "@hellodarwin/core/lib/theme";

export type DefaultTheme = typeof theme;
const theme = {
  colors: { ...coreTheme.colors },
  breakpoints: {
    mobile: 700,
    tablet: 991,
    l: 1200,
    xl: 1450,
  },
  fontFamily: {
    primary: "Inter, Figtree, Helvetica, Arial, sans-serif, Area Normal,",
  },
  fonts: {
    h1: "70px",
    h1_md: "52px",

    h2: "52px",
    h2_md: "40px",

    h3: "40px",
    h3_md: "32px",

    h4: "32px",
    h4_md: "28px",

    h5: "28px",
    h5_md: "24px",

    h6: "22px",
    h6_md: "20px",

    subtitle1: "20px",
    subtitle1_md: "18px",

    subtitle2: "18px",
    subtitle2_md: "16px",

    body1: "18px",
    body1_md: "16px",

    body2: "16px",
    body2_md: "14px",

    body3: "14px",
    body3_md: "14px",

    caption: "14px",
    caption_md: "12px",

    overline: "11px",
    overline_md: "10px",

    button_large: "18px",
    button_large_md: "16px",

    button_medium: "16px",
    button_medium_md: "15px",

    button_small: "15px",
    button_small_md: "14px",

    button_extra_small: "15px",
    button_extra_small_md: "14px",

    link_l: "20px", // min 1200px
    link_md: "20px", // min 960px
    link: "18px", // min 0
  },
  fontsLineHeights: {
    h1: "66px",
    h1_md: "52px",

    h2: "54px",
    h2_md: "42px",

    h3: "42px",
    h3_md: "36px",

    h4: "34px",
    h4_md: "32px",

    h5: "30px",
    h5_md: "28px",

    h6: "24px",
    h6_md: "24px",

    subtitle1: "28px",
    subtitle1_md: "26px",

    subtitle2: "24px",
    subtitle2_md: "20px",

    body1: "28px",
    body1_md: "28px",

    body2: "24px",
    body2_md: "24px",

    body3: "18px",
    body3_md: "18px",

    caption: "16px",
    caption_md: "14px",

    overline: "12px",
    overline_md: "12px",

    button_large: "18px",
    button_large_md: "16px",

    button_medium: "16px",
    button_medium_md: "15px",

    button_small: "15px",
    button_small_md: "14px",

    button_extra_small: "15px",
    button_extra_small_md: "14px",

    link_l: "0", // min 1200px
    link_md: "0", // min 960px
    link: "0", // min 0
  },
  letterSpacing: {
    h1: 0,
    h1_md: 0,

    h2: 0,
    h2_md: 0,

    h3: 0,
    h3_md: 0,

    h4: 0,
    h4_md: 0,

    h5: 0,
    h5_md: 0,

    h6: 0,
    h6_md: 0,

    subtitle1: 0,
    subtitle1_md: 0,

    subtitle2: 0,
    subtitle2_md: 0,

    body1: 0,
    body1_md: 0,

    body2: 0,
    body2_md: 0,

    body3: 0,
    body3_md: 0,

    caption: 0,
    caption_md: 0,

    overline: 0.03,
    overline_md: 0.03,

    button_large: 0.36,
    button_large_md: 0.36,

    button_medium: 0.16,
    button_medium_md: 0.16,

    button_small: 0,
    button_small_md: 0,

    button_extra_small: 0,
    button_extra_small_md: 0,

    link_l: 0,
    link_md: 0,
    link: 0,
  },
  caps: {
    small: 960,
    normal: 1120,
    big: 1260,
    xl: 1440,
    auto: "auto",
  },
  fontWeights: {
    bold: 700,
    semibold: 600,
    medium: 500,
    regular: 400,
    light: 300,
    headings: 600,
  },
  buttons: {
    width_extra_small: "160px",
    height_extra_small: "24px",

    width_small: "190px",
    height_small: "32px",

    width_medium: "220px",
    height_medium: "40px",

    width_large: "260px",
    height_large: "48px",
  },
  appearance: {
    border_radius: "24px",
    border_radius_button: "99px",
    box_shadow: "0px 0px 10px rgba(0, 0, 0, 0.05)",
    box_shadow_hover: "0px 2px 10px rgba(0, 0, 0, 0.1)",
    box_shadow_high_contrast: "2px 2px 5px 3px rgba(43,43,43,0.1)",
    box_shadow_high_contrast_hover: "2px 2px 5px 3px rgba(43,43,43,0.4)",
    transition: "all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1)",
    headerHeight: 73,
    dynamicSliderHeight: 66 * 2 + 32,
    bannerHeight: 94,
  },
};

export default theme;

export const anchor = css`
  color: ${({ theme }) => theme.colors.purple_2};
  text-decoration: underline;
  &:hover {
    color: ${({ theme }) => theme.colors.purple_1};
    text-decoration: none;
  }
  &:active {
    color: ${({ theme }) => theme.colors.purple_3};
  }
  text-underline-offset: 8px;
`;
export const li = css`
  padding-inline: 4px 0;
  margin-inline: 24px 0;
  margin-block: 4px 0;
`;
export const ul = css`
  list-style-position: outside;
  margin-block: 0;
  * {
    vertical-align: top;
  }
`;
export const ol = css`
  list-style-position: outside;
  margin-block: 0;
  * {
    vertical-align: top;
  }
`;

export const p = css`
  font-size: ${(props) => props?.theme?.fonts?.body1};
  letter-spacing: ${(props) => props?.theme?.letterSpacing?.body};
  font-weight: 400;
  font-style: normal;
  line-height: 1.55;

  @media (max-width: ${(props) => props.theme.breakpoints.tablet}px) {
    font-size: ${(props) => props?.theme?.fonts?.body_md};
    letter-spacing: ${(props) => props?.theme?.letterSpacing?.body_md};
  }
`;

export const small = css`
  font-size: ${(props) => props?.theme?.fonts?.body_small};
  letter-spacing: ${(props) => props?.theme?.letterSpacing?.body_small};
  line-height: 1.5;

  @media (max-width: ${(props) => props.theme.breakpoints.tablet}px) {
    font-size: ${(props) => props?.theme?.fonts?.body_small_md};
    letter-spacing: ${(props) => props?.theme?.letterSpacing?.body_small_md};
  }
`;

export const blockquote = css`
  font-size: ${(props) => props?.theme?.fonts?.blockquote};
  letter-spacing: ${(props) => props?.theme?.letterSpacing?.blockquote};

  @media (max-width: ${(props) => props.theme.breakpoints.tablet}px) {
    font-size: ${(props) => props?.theme?.fonts?.blockquote_md};
    letter-spacing: ${(props) => props?.theme?.letterSpacing?.blockquote_md};
  }
`;

export const h1 = css`
  font-family: Figtree;
  font-size: ${(props) => props?.theme?.fonts?.h1};
  letter-spacing: ${(props) => props?.theme?.letterSpacing?.h1};
  font-weight: 700;
  line-height: 1.09;

  * {
    font-size: inherit;
    font-weight: inherit;
    line-height: inherit;
  }
`;
export const h2 = css`
  font-family: "Figtree";
  font-size: ${(props) => props?.theme?.fonts?.h2};
  letter-spacing: ${(props) => props?.theme?.letterSpacing?.h2};
  font-weight: 700;
  line-height: 1.3;

  * {
    font-size: inherit;
    font-weight: inherit;
    line-height: inherit;
  }
`;
export const h3 = css`
  font-family: Figtree;
  font-size: ${(props) => props?.theme?.fonts?.h3};
  letter-spacing: ${(props) => props?.theme?.letterSpacing?.h3};
  font-weight: 600;
  line-height: 1;

  * {
    font-size: inherit;
    font-weight: inherit;
    line-height: inherit;
  }
`;
export const h4 = css`
  font-family: Figtree;
  font-size: ${(props) => props?.theme?.fonts?.h4};
  letter-spacing: ${(props) => props?.theme?.letterSpacing?.h4};
  font-weight: 600;
  line-height: 1;

  * {
    font-size: inherit;
    font-weight: inherit;
    line-height: inherit;
  }
`;
export const h5 = css`
  font-size: ${(props) => props?.theme?.fonts?.h5};
  letter-spacing: ${(props) => props?.theme?.letterSpacing?.h5};
  font-weight: 500;
  line-height: 1;
  font-family: Figtree;

  * {
    font-size: inherit;
    font-weight: inherit;
    line-height: inherit;
  }
`;

export const h6 = css`
  font-family: Figtree;
  font-size: ${(props) => props?.theme?.fonts?.h6};
  letter-spacing: ${(props) => props?.theme?.letterSpacing?.h6};
  font-weight: 500;
  line-height: 1;

  * {
    font-size: inherit;
    font-weight: inherit;
    line-height: inherit;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.tablet}px) {
    font-size: ${(props) => props?.theme?.fonts?.h6_md};
    letter-spacing: ${(props) => props?.theme?.letterSpacing?.h6_md};
    line-height: 1.4;
  }
`;

export const defaultTheme = css`
  h1 {
    ${h1};
  }

  h2 {
    ${h2};
  }

  h3 {
    ${h3};
  }

  h4 {
    ${h4}
  }

  h5 {
    ${h5};
  }

  p,
  input {
    ${p};
  }

  p {
    display: block;
  }

  small {
    ${small};
  }

  blockquote {
    ${blockquote};
  }

  a {
    color: inherit;
    &:hover {
      color: inherit;
    }
    text-decoration: none;
  }

  ul,
  ol {
    list-style-position: outside;
    margin-block: 12px;
    margin-inline-start: 24px;
    li {
      margin-block: 6px;
      padding-inline-start: 8px;
      & > * {
        display: inline;
      }
    }
  }
`;

