import React, { PropsWithChildren, useContext, useEffect } from "react";
import { useLocation, useSessionStorage } from "react-use";

interface UrlParamsEntity {
  campaignid: string | null;
  adgroupid: string | null;
  kw: string | null;
  gclid: string | null;
  source_url: string | null;
  lp_url: string | null;
  fbclid: string | null;
  referral_code: string | null;
}
const InitialUrlParamsEntity: UrlParamsEntity = {
  campaignid: null,
  adgroupid: null,
  kw: null,
  gclid: null,
  source_url: null,
  lp_url: null,
  fbclid: null,
  referral_code: null,
};
export interface URLParamContextType {
  urlParams: UrlParamsEntity;
}

export const URLParamContext = React.createContext<URLParamContextType>({
  urlParams: InitialUrlParamsEntity,
});

export const URLParamProvider = ({ children }: PropsWithChildren) => {
  const location = useLocation();
  const currentUrl = location.href;

  const [urlParams, setUrlParams] = useSessionStorage<UrlParamsEntity>(
    "campaign-url-params",
    undefined
  );
  const [landingPageUrl, setLandingPageUrl] = useSessionStorage<string>(
    "lp_url",
    undefined
  );

  useEffect(() => {
    if (!landingPageUrl && !!currentUrl) {
      setLandingPageUrl(currentUrl);
    }
  }, [currentUrl]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);

    let campaignid = urlParams?.campaignid || null;
    let adgroupid = urlParams?.adgroupid || null;
    let kw = urlParams?.kw || null;
    let gclid = urlParams?.gclid || null;
    let fbclid = urlParams?.fbclid || null;
    let referral_code = urlParams?.referral_code || null;
    let lp_url = landingPageUrl || null;

    if (!!location?.search) {
      campaignid = !!campaignid ? campaignid : params.get("campaignid");
      adgroupid = !!adgroupid ? adgroupid : params.get("adgroupid");
      kw = !!kw ? kw : params.get("kw");
      gclid = !!gclid ? gclid : params.get("gclid");
      fbclid = !!fbclid ? fbclid : params.get("fbclid");
      referral_code = !!referral_code
        ? referral_code
        : params.get("referral_code");
      lp_url = landingPageUrl;
    }

    const newUrlParams: UrlParamsEntity = {
      campaignid,
      adgroupid,
      kw,
      gclid,
      source_url: `${currentUrl}`,
      lp_url,
      fbclid,
      referral_code,
    };

    setUrlParams(newUrlParams);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search, landingPageUrl]);

  return (
    <URLParamContext.Provider
      value={{
        urlParams,
      }}
    >
      {children}
    </URLParamContext.Provider>
  );
};

export const useURLParam = () => useContext(URLParamContext);

export default URLParamProvider;

