import {
  CollapseContainer,
  CollapsePanelContainer,
  CollapsePanelHeader,
} from "@hellodarwin/core/lib/components/common/Collapse/styles";
import Button from "@hellodarwin/core/lib/components/common/button";
import { ArrowContainer } from "@hellodarwin/core/lib/components/common/button/arrow/styles";
import Div from "@hellodarwin/core/lib/components/common/div";
import styled, { css } from "@hellodarwin/core/lib/plugins/styled";
import Drawer from "antd/es/drawer";
import { Link } from "gatsby";
import { MenuItemEntity } from "../../../../features/entities/layout-entities";
import theme, { anchor } from "../../../../utils/styles/theme";
import { ChildItemSize } from "./components/ChildItem/Wrapper";

const menuHoverStyle = css`
  background: ${({ theme }) => theme.colors.beige_2};
  color: ${({ theme }) => theme.colors.grey_1};

  ${ArrowContainer} {
    width: 30px;
  }
`;

interface MenuItemStyleProps {
  $isFeatured?: boolean;
  $isActive?: boolean;
}
const menuItemStyle = css<MenuItemStyleProps>`
  display: flex;
  flex-direction: row;
  gap: 24px;
  align-items: flex-start;
  transition: background 400ms ease;
  border-radius: 4px;
  background-color: transparent;
  text-align: left;
  cursor: pointer;

  ${ArrowContainer} {
    width: 20px;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.l}px) {
    &:hover {
      ${menuHoverStyle}
    }
    ${({ $isActive }) => ($isActive ? menuHoverStyle : "")}
  }

  ${({ $isFeatured, theme }) =>
    $isFeatured
      ? css`
          color: ${theme.colors.purple_1};
          text-decoration-line: underline;
          text-underline-offset: 4px;
          &:hover {
            color: ${theme.colors.purple_2};
            text-decoration-line: underline;
            text-underline-offset: 4px;
          }
        `
      : undefined}

  &::before,
  &::after {
    display: none;
  }
`;

export const LargeMenuItemContainer = styled.div<MenuItemStyleProps>`
  ${menuItemStyle}

  padding: 30px 24px;

  @media (max-width: ${({ theme }) => theme.breakpoints.l}px) {
    padding: 0;
  }
`;

export const LargeMenuItemBody = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;
export const MenuItemTitle = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: center;
  justify-content: space-between;
`;

export const SmallMenuItemContainer = styled(Div)<MenuItemStyleProps>`
  ${menuItemStyle}
  padding: 7px 24px;
  font-size: 18px;
  line-height: 24px;
  font-weight: ${({ theme }) => theme.fontWeights.regular};
  @media (max-width: ${({ theme }) => theme.breakpoints.l}px) {
    padding: 0;
  }
`;

export const MediumMenuItemContainer = styled(Div)<MenuItemStyleProps>`
  ${menuItemStyle}
  letter-spacing: 0.36px;
`;

export const ArticleMenuItemContainer = styled(Div)<MenuItemStyleProps>`
  ${menuItemStyle}
  width:333px;
  max-width: 100%;
  padding: 0;
  gap: 0;
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.colors.primary};
  font-size: 14px;
  line-height: 18px;
  align-items: stretch;
  overflow: hidden;
`;

export const MenuDrawer = styled(Drawer)<{
  $direction?: MenuItemEntity["direction"];
}>`
  margin-top: ${({ theme }) => theme.appearance.headerHeight}px;
  min-height: ${({ $direction }) => ($direction === "column" ? 288 : 144)}px;
  height: calc(100vh - ${({ theme }) => theme.appearance.headerHeight}px);
  a {
    color: inherit;
    &:hover {
      color: inherit;
    }
  }
`;

const ChildNavItemLinkSmall = css`
  padding: 0 16px;

  &:first-of-type {
    padding-top: 25px;
  }
  &:last-of-type {
    padding: 16px 0;
  }
`;
const ChildNavItemLinkLarge = css`
  position: relative;

  padding: 8px;
`;

const ChildNavItemLinkMedium = css`
  padding: 11px 16px;

  &:first-of-type {
    padding-top: 22px;
  }
`;
const ChildNavItemLinkArticle = css``;

interface LinkWrapperStyleProps {
  $type: ChildItemSize;
  $isFeatured?: boolean;
}
export const LinkWrapperStyling = css<LinkWrapperStyleProps>`
  display: block;

  ${({ $type }) =>
    $type === "article"
      ? ChildNavItemLinkArticle
      : $type === "small"
        ? ChildNavItemLinkSmall
        : $type === "large"
          ? ChildNavItemLinkLarge
          : ChildNavItemLinkMedium}

  &:hover {
    ${ArrowContainer} {
      width: 30px;
    }
  }
`;
export const LinkWrapperExternalLinkContainer = styled.a<LinkWrapperStyleProps>`
  ${LinkWrapperStyling}
`;
export const LinkWrapperInternallLinkContainer = styled(
  Link
)<LinkWrapperStyleProps>`
  ${LinkWrapperStyling}
`;
export const LinkWrapperClickContainer = styled.div<LinkWrapperStyleProps>`
  ${LinkWrapperStyling}
`;

const MenuButtonTypographyHover = css`
  text-underline-offset: 6px;
  text-decoration: underline;
  cursor: pointer;
`;

export const MenuButtonTypography = styled(Button)<{ $isOpen?: boolean }>`
  &:before,
  &:after {
    display: none;
  }
  display: flex;
  gap: 8px;
  font-family: Figtree;
  font-weight: ${theme.fontWeights.medium};
  align-items: center;
  min-width: auto;
  padding: 0;
  ${({ $isOpen }) => ($isOpen ? MenuButtonTypographyHover : "")}
  &:hover {
    ${MenuButtonTypographyHover}
  }
  svg {
    path {
      strokewidth: 2.5;
    }
  }
`;

export const MenuItemsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  width: 100%;
  & > * {
    border-right: 1px solid ${({ theme }) => theme.colors.primary};
    &:nth-child(3) {
      border: none;
    }
    @media (max-width: ${({ theme }) => theme.breakpoints.l}px) {
      border: none;
      border-bottom: 1px solid ${({ theme }) => theme.colors.primary};
      &:last-child {
        border: none;
      }
    }
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.l}px) {
    display: flex;
    flex-direction: column;
  }
`;

export const ChildListItemsContainer = styled(Div)`
  width: 100%;

  &.services,
  &.articles,
  &.podcasts {
    & > a${LinkWrapperInternallLinkContainer} {
      @media (max-width: ${({ theme }) => theme.breakpoints.l}px) {
        margin: 0;
        padding: 8px 0 !important;
      }
      &:last-of-type {
        ${SmallMenuItemContainer} {
          color: ${({ theme }) => theme.colors.purple_1};
          text-decoration: underline;
          font-weight: bold;
          padding: 0;
          margin: 16px 24px;

          &:hover {
            background-color: transparent;
            text-decoration: none;
            color: ${({ theme }) => theme.colors.purple_3};
          }
          @media (max-width: ${({ theme }) => theme.breakpoints.l}px) {
            margin: 0;
          }
        }
      }
    }
  }

  &.services {
    ${LinkWrapperInternallLinkContainer}:last-of-type {
      ${SmallMenuItemContainer} {
        margin: 16px 38px;
        @media (max-width: ${({ theme }) => theme.breakpoints.l}px) {
          margin: 5px 0;
        }
      }
    }
  }
`;
export const ItemsListContainer = styled.div`
  & > * {
    &::after {
      content: "";
      width: 100vw;
      position: absolute;
      bottom: 0;
      right: 0;
      border-bottom: 1px solid ${theme.colors.primary};
    }
    @media (max-width: ${theme.breakpoints.l}px) {
      &::after {
        display: none;
      }
      border-bottom: 1px solid ${theme.colors.primary};
    }
    &:last-of-type {
      border: none;
      &:after {
        display: none;
      }
    }
  }
`;

export const LinkContainer = styled.div`
  margin: 24px 41px;
  @media (max-width: ${({ theme }) => theme.breakpoints.l}px) {
    margin: 0;
  }
`;

export const RecentSection = styled(Div)`
  padding: 5px 24px;
  padding-top: 32px;
  ${LinkWrapperInternallLinkContainer} {
    margin: 0;

    &,
    &:first-of-type,
    &:last-of-type {
      ${ArticleMenuItemContainer} {
        margin: 0;
      }
    }
  }
`;

export const MobileDrawerContentStyling = css`
  padding: 24px;
  ${ItemsListContainer},${MenuItemsContainer} , {
    flex-direction: column;

    & > * {
      padding: 0;
      ${LinkWrapperClickContainer},${LinkWrapperExternalLinkContainer} ,${LinkWrapperInternallLinkContainer} {
        padding: 16px 0 !important;
        & > * > * {
          width: 100% !important;
        }
      }
      &:first-of-type {
        padding: 0;
      }
      &:last-of-type {
        padding: 0;
      }
    }
  }
  ${ChildListItemsContainer} {
    & > * {
      padding: 0;
      & > * {
        padding: 0 12px !important;
        &:first-of-type {
          padding: 0 12px !important;
        }
        &:last-of-type {
          padding: 0 12px !important;
        }
      }
    }
  }
  ${RecentSection} {
    margin: 0;
    margin-bottom: 22px;

    ${LinkWrapperInternallLinkContainer} {
      margin-bottom: 0;
    }
  }
`;

export const MobileDrawer = styled(Drawer)`
  margin-top: ${({ theme }) => theme.appearance.headerHeight}px;
  height: calc(
    100dvh - ${({ theme }) => theme.appearance.headerHeight}px
  ) !important;

  .ant-drawer-header-title {
    align-items: center;
    justify-content: space-between;
    .ant-drawer-close {
    }
    .ant-drawer-title {
      width: fit-content;
    }
  }

  .ant-drawer-header {
    padding: 24px !important;
    height: auto;
  }
  .ant-drawer-body {
    ${MobileDrawerContentStyling}
  }
  ${CollapseContainer} {
    & > ${CollapsePanelContainer} {
      border: none;
      border-bottom: 1px solid ${({ theme }) => theme.colors.primary};
      & > ${CollapsePanelHeader} {
        padding: 16px 24px;
        padding-bottom: 8px;
        margin: 0;
      }
      .ReactCollapse--content {
        border: none;
        background: transparent;
        ${MobileDrawerContentStyling}
        padding: 12px 24px;
        padding-top: 0;
        .ant-collapse-content-box,
        .menu-section {
          padding: 0;
          width: 100% !important;
        }
      }
    }
  }
`;

export const SeeMoreLink = styled(Link)`
  &.see-more-link {
    margin-top: 16px;
    font-size: 15px;
    font-weight: 500;
    ${anchor}
  }
`;

